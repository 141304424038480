import React from 'react'
import { useTranslate } from 'react-polyglot'
import { Box, Grid } from '@chatterbug/aaron'

import { BlogPost, OrgItemName } from 'src/types'
import PageContainer from 'src/components/PageContainer/PageContainer'
import SectionSeparator from 'src/sections/SectionSeparator/SectionSeparator'
import BlogPostCard from '../BlogPostCard/BlogPostCard'
import { CB_LINKS } from 'src/lib/app'
import { useLocale } from 'src/lib/I18nContext'

export type Props = {
  posts: BlogPost[]
}

const OrgNews: React.FC<Props> = ({ posts }) => {
  const t = useTranslate()
  const locale = useLocale()

  return (
    <PageContainer>
      <SectionSeparator name={OrgItemName.news} />
      <Box
        variant="text.paragraph"
        textAlign="center"
        mt="3x"
        dangerouslySetInnerHTML={{
          __html: t(
            'For more company news please visit our <a href=%{blogLink} class="link" target="_blank" rel="noopener">Blog</a>',
            {
              blogLink: `${CB_LINKS.blog}/${locale}`,
            }
          ),
        }}
      />
      <Grid
        as="ul"
        gridTemplateColumns={{ _: '1fr', tablet: '1fr 1fr 1fr' }}
        gridGap={{ _: '2x', laptop: '3x' }}
        sx={{ listStyle: 'none', padding: 0 }}
        mt="5x"
      >
        {posts.map((post) => (
          <Box as="li" key={post.id} data-testid="blog-post">
            <BlogPostCard post={post} />
          </Box>
        ))}
      </Grid>
    </PageContainer>
  )
}

export default OrgNews
