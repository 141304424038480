import React from 'react'
import { Box, Grid } from '@chatterbug/aaron'

import Button, { BUTTON_VARIANTS } from 'src/components/Button/Button'

export type Props = {
  logoSection: React.ReactNode
  points: string[]
  ctaLabel: string
  ctaLink: string
}

const OrgPackage: React.FC<Props> = ({
  logoSection,
  points,
  ctaLabel,
  ctaLink,
}) => {
  return (
    <Grid
      as="section"
      borderRadius={16}
      bg="white100"
      p="3x"
      columns={1}
      gridGap="3x"
      boxShadow="raised"
      minHeight="100%"
    >
      {logoSection}
      <Box as="ul" p={0} pl="4x">
        {points.map((point, index) => (
          <Box
            key={index}
            as="li"
            variant="text.paragraph"
            color="black19"
            dangerouslySetInnerHTML={{
              __html: point,
            }}
          />
        ))}
      </Box>
      <Button
        label={ctaLabel}
        href={ctaLink}
        height="44px"
        width="100%"
        variant={BUTTON_VARIANTS.brand}
      />
    </Grid>
  )
}

export default OrgPackage
