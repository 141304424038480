import React from 'react'
import { Box, Flex, Image, Grid } from '@chatterbug/aaron'
import { useTranslate } from 'react-polyglot'

import mobile from './assets/mobile.svg'
import desktop from './assets/desktop.svg'
import Button, { BUTTON_VARIANTS } from 'src/components/Button/Button'

export type Props = {
  title: string
  description: string
  platformType: 'mobile' | 'desktop'
  ctaLabel: string
  ctaLink: string
  iconSection: React.ReactNode
  previewSection: React.ReactNode
  previewPosition: 'left' | 'right'
}

const OrgProduct: React.FC<Props> = ({
  title,
  description,
  platformType,
  ctaLabel,
  ctaLink,
  iconSection,
  previewSection,
  previewPosition,
}) => {
  const t = useTranslate()

  return (
    <Grid
      gridTemplateColumns={{ _: '1fr', tablet: '1fr 1fr' }}
      gridTemplateAreas={{
        _: `'preview' 'content'`,
        tablet:
          previewPosition === 'left'
            ? `'preview content'`
            : `'content preview'`,
      }}
      gridGap={{ _: '2x', tablet: '6x' }}
    >
      <Box sx={{ gridArea: 'preview' }}>{previewSection}</Box>
      <Box sx={{ gridArea: 'content' }}>
        {iconSection}
        <Box variant="text.h5" color="black0" mt="3x">
          {title}
        </Box>
        <Box variant="text.paragraph" color="grey46" mt="3x">
          {description}
        </Box>
        <Flex flexDirection="row" alignItems="center" mt="3x">
          <Image src={platformType === 'mobile' ? mobile : desktop} alt="" />
          <Box variant="text.buttonTitle" color="black19" ml="2x">
            {platformType === 'mobile'
              ? t('Available on ios & android')
              : t('Best on desktop or laptop')}
          </Box>
        </Flex>
        <Box mt="6x">
          <Button
            label={ctaLabel}
            height="44px"
            href={ctaLink}
            variant={BUTTON_VARIANTS.brand}
          />
        </Box>
      </Box>
    </Grid>
  )
}

export default OrgProduct
