import React, { useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { Box, Flex } from '@chatterbug/aaron'

import PageContainer from 'src/components/PageContainer/PageContainer'
import FadeIn from 'src/components/FadeIn/FadeIn'
import Tab from 'src/components/Tab/Tab'
import SectionSeparator from 'src/sections/SectionSeparator/SectionSeparator'
import CardWithImageAndCta from 'src/components/CardWithImageAndCta/CardWithImageAndCta'
import { CB_LINKS } from 'src/lib/app'
import { OrgItemName } from 'src/types'

import community from './assets/community@2x.png'
import chatterconf from './assets/chatterconf.svg'
import podcasts from './assets/podcasts@2x.png'

const getLabLabelId = (name: string): string => `product-label-${name}`

const OrgMoreProducts: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const t = useTranslate()

  const products = [
    {
      name: 'community',
      tab: t('Community'),
      title: t('Community Forum'),
      subtitle: t('Get involved'),
      description: t(
        'Chatterbug hosts a forum for all language learners to help people connect and learn from one another. We also share ongoing news about Chatterbug and upcoming events.'
      ),
      link: {
        label: t('Explore the community'),
        url: CB_LINKS.community,
      },
      image: community,
    },
    {
      name: 'chatterconf',
      tab: t('Chatterconf'),
      title: t('Chatterconf'),
      subtitle: t('Our annual event'),
      description: t(
        'Every year Chatterbug holds a virtual conference all about language learning and technology! We bring together language enthusiasts from all around the world to learn about the world of online language learning.'
      ),
      link: {
        label: t("Check out this year's lineup"),
        url: CB_LINKS.chatterconf,
      },
      image: chatterconf,
    },
    {
      name: 'podcasts',
      tab: t('Podcasts'),
      title: t('Podcasts to Broaden your Language Skills'),
      subtitle: t('Listen in'),
      description: t(
        '%{LongStoryShort} features fictional short stories with dialog describing the cultural aspects of living in Germany and key grammar explanations for beginners. The podcasts %{HotAsIce} and %{LesLarmesDesAnges} follows the adventures of three roommates whose lives will be turned upside down after the arrival of a strange package. These podcasts are geared towards English or French learning at an advanced level (CEFR B2-C1).',
        {
          LongStoryShort: `<a href="${CB_LINKS.podcasts.de}" class="link">Long Story Short</a>`,
          HotAsIce: `<a href="${CB_LINKS.podcasts.en}" class="link">Hot As Ice</a>`,
          LesLarmesDesAnges: `<a href="${CB_LINKS.podcasts.fr}" class="link">Les Larmes Des Anges</a>`,
        }
      ),
      image: podcasts,
    },
  ]

  return (
    <PageContainer>
      <Box>
        <SectionSeparator name={OrgItemName.moreProducts} />
        <Box
          variant="text.paragraph"
          textAlign="center"
          mt={64}
          mx="auto"
          maxWidth={{
            tabletWide: `${(9 * 100) / 12}%`,
            laptop: `${(8 * 100) / 12}%`,
          }}
        >
          {t(
            'Get involved in more than just language learning! Engage with like minded folks on our expansive community forum with topics on grammar and culture, whilst tuning in to our wonderfully produced language learning podcast series'
          )}
        </Box>
        <Flex
          as="ul"
          sx={{ listStyle: 'none', padding: 0, margin: 0 }}
          justifyContent="center"
          flexWrap="wrap"
          mt="5x"
          mx="auto"
          maxWidth={{
            tablet: `${(8 * 100) / 12}%`,
            tabletWide: '100%',
          }}
        >
          {products.map((product, index) => (
            <Box
              as="li"
              key={`product-${product.name}`}
              ml={{ _: 8, laptop: 24 }}
              mr={{ _: 8, laptop: 24 }}
              mt={16}
            >
              <Tab
                label={product.tab}
                name={product.name}
                active={currentIndex === index}
                onClick={() => setCurrentIndex(index)}
              />
            </Box>
          ))}
        </Flex>
        <Box
          as="ul"
          mt={{ _: 48, tablet: 80 }}
          sx={{ listStyle: 'none', padding: 0 }}
        >
          {products.map((product, index) => {
            const isCurrent = currentIndex === index

            return (
              <FadeIn
                as="li"
                key={`product-desc-${product.name}-${currentIndex}`}
                display={isCurrent ? 'grid' : 'none'}
                aria-hidden={!isCurrent}
              >
                <CardWithImageAndCta
                  id={getLabLabelId(product.name)}
                  title={product.title}
                  subtitle={product.subtitle}
                  description={product.description}
                  image={{
                    url: product.image,
                    width: 368,
                    height: 278,
                  }}
                  primaryAction={product.link}
                  className="cb_product"
                />
              </FadeIn>
            )
          })}
        </Box>
      </Box>
    </PageContainer>
  )
}

export default OrgMoreProducts
