import React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'src/components/Layout/Layout'
import Head from 'src/components/Head/Head'
import OrgIndex from 'src/sections/OrgIndex/OrgIndex'
import { PageContext, BlogPostsJSON } from 'src/types'

type DataProps = {
  blogFeed: BlogPostsJSON
}

const OrgPage: React.FC<PageProps<DataProps, PageContext>> = ({
  pageContext,
  data,
}) => {
  return (
    <Layout pageContext={pageContext} hideSiteHeader>
      <Head pageContext={pageContext} />
      <OrgIndex
        pageContext={pageContext}
        blogPosts={data?.blogFeed?.nodes || null}
      />
    </Layout>
  )
}

export default OrgPage

export const query = graphql`
  query($locale: String) {
    blogFeed(locale: { eq: $locale }) {
      nodes {
        id
        title
        description
        imageUrl
        link
      }
    }
  }
`
