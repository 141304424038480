import React from 'react'
import { Box, Flex, Grid, Image } from '@chatterbug/aaron'

import desktopScreen from './assets/desktop-screen.svg'
import stand from './assets/stand.svg'

type Props = {
  children?: JSX.Element
  background?: {
    // If the background image conveys contextual information that the surrounding
    // text does not, please provide a descriptive text
    accessibilityText?: string
    url: string
  }
}
const DesktopScreen: React.FC<Props> = ({ children, background }) => {
  // TODO: on poor networks the video autoplay can buffer/stop a lot
  // only play the video whe the document has stopped downloading
  //TODO: triple check there is no background color needed on different screen sizes
  // put in a black sreen background for those cases and make sure the edges
  // of the player are rounded
  return (
    <Grid gridTemplateColumns="1fr" gridTemplateRows="1fr auto">
      <Flex
        className="video-container"
        borderRadius="20px"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        gridColumn="1/1"
        gridRow="1"
        gridGap="0"
        zIndex="0"
        overflow="hidden"
        width="99%"
      >
        <Box
          height="calc(100% - 5px)"
          width="calc(100% - 5px)"
          sx={
            background?.url
              ? {
                  background: `url(${background?.url}) no-repeat`,
                  backgroundSize: 'contain',
                  backgroundPosition: 'center center',
                }
              : undefined
          }
          overflow="hidden"
          borderRadius="20px"
          role={background?.accessibilityText ? 'img' : 'presentation'}
          aria-label={background?.accessibilityText}
        >
          {children}
        </Box>
      </Flex>
      <Box
        data-testid="screen"
        zIndex="1"
        gridColumn="1/1"
        gridRow="1"
        width="100%"
        sx={{ pointerEvents: 'none' }}
        overflow="hidden"
      >
        <Image src={desktopScreen} alt="" />
      </Box>
      <Box
        gridColumn="1/2"
        gridRow="2"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        overflow="visible"
        data-testid="stand"
      >
        <Image
          src={stand}
          alt=""
          marginTop={{ _: '-10px', desktop: '-35px' }}
        />
      </Box>
    </Grid>
  )
}

export default DesktopScreen
