import React from 'react'
import { Box } from '@chatterbug/aaron'

import { BlogPost, OrgItemName, PageContext } from 'src/types'
import OrgHero from './subcomponents/OrgHero/OrgHero'
import OrgProducts from './subcomponents/OrgProducts/OrgProducts'
import OrgMoreProducts from './subcomponents/OrgMoreProducts/OrgMoreProducts'
import OrgAboutUs from './subcomponents/OrgAboutUs/OrgAboutUs'
import OrgPackages from './subcomponents/OrgPackages/OrgPackages'
import OrgNews from './subcomponents/OrgNews/OrgNews'
import SectionSeparator from 'src/sections/SectionSeparator/SectionSeparator'
import { OrgThemeProvider } from 'src/lib/pageThemeContext'

export type Props = {
  blogPosts: BlogPost[] | null
  pageContext: PageContext
}

const OrgIndex: React.FC<Props> = ({ pageContext, blogPosts }) => {
  return (
    <OrgThemeProvider>
      <OrgHero pageContext={pageContext} />
      <Box as="section" id={OrgItemName.products}>
        <SectionSeparator name={OrgItemName.products} />
        <Box mt={112}>
          <OrgProducts />
        </Box>
      </Box>
      <Box
        as="section"
        id={OrgItemName.moreProducts}
        mt={{
          _: 64,
          laptop: 88,
        }}
      >
        <OrgMoreProducts />
      </Box>
      {blogPosts && blogPosts.length > 0 && (
        <Box
          as="section"
          id={OrgItemName.news}
          mt={{
            _: 100,
            laptop: 160,
          }}
        >
          <OrgNews posts={blogPosts} />
        </Box>
      )}
      <Box as="section" bg="white100" mt={{ _: 80, tablet: 128 }} mb={-1}>
        <Box py={128}>
          <OrgAboutUs />
        </Box>
        <OrgPackages />
      </Box>
    </OrgThemeProvider>
  )
}

export default OrgIndex
