import React from 'react'
import { useTranslate } from 'react-polyglot'
import { Box, Flex } from '@chatterbug/aaron'

import { PageContext } from 'src/types'
import { getNavigationItems } from 'src/lib/navigation'
import PageContainer from 'src/components/PageContainer/PageContainer'
import Header from 'src/components/Header/Header'
import { useLocale } from 'src/lib/I18nContext'

import background from './assets/background.svg'
import cover from './assets/cover.svg'
import FullWidthBackground from 'src/components/FullWidthBackground/FullWidthBackground'

const COVER_WAVE_HEIGHT = 49

export type Props = {
  pageContext: PageContext
}

const OrgHero: React.FC<Props> = ({ pageContext }) => {
  const locale = useLocale()
  const t = useTranslate()

  return (
    <Box
      sx={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        position: 'relative',
        marginBottom: -8,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          height: COVER_WAVE_HEIGHT,
        }}
      >
        <FullWidthBackground
          image={cover}
          width={1440}
          height={COVER_WAVE_HEIGHT}
        />
      </Box>
      <PageContainer>
        <Header
          canonicalPath={pageContext.canonicalPath}
          items={getNavigationItems(t, locale)}
        />
        <Flex
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          minHeight={{
            _: 372,
            mobile: 420,
          }}
          py={{ _: 40, tablet: 104 }}
          pb={{
            _: 40 + COVER_WAVE_HEIGHT,
            tablet: 104 + COVER_WAVE_HEIGHT,
          }}
        >
          <Box
            as="h1"
            variant="text.h1"
            lineHeight={1.2}
            textAlign="center"
            color="black0"
            fontSize={{
              _: 28,
              mobile: 52,
              tablet: 64,
            }}
            maxWidth={890}
            dangerouslySetInnerHTML={{
              __html: t(
                '<strong>Chatterbug</strong> helps you learn languages from <strong>real people</strong>'
              ),
            }}
          />
        </Flex>
      </PageContainer>
    </Box>
  )
}

export default OrgHero
