import React from 'react'

import DesktopScreen from 'src/components/DesktopScreen/DesktopScreen'
import InlineVideo from 'src/components/InlineVideo/InlineVideo'
import { useLocale } from 'src/lib/I18nContext'
import { useMediaQueryOnClient } from 'src/lib/use-helpers'
import { SiteLocale } from 'src/types'

const ProductLessonsPreview: React.FC = () => {
  const locale = useLocale()
  const isPhone = useMediaQueryOnClient('only screen and (max-width: 768px)')

  const videoLocale = locale === SiteLocale.en ? SiteLocale.de : SiteLocale.en
  const videoUrl = `/assets/videos/lessons-${videoLocale}-${
    isPhone ? 'mobile' : 'desktop'
  }.mp4`
  return (
    <DesktopScreen>
      <InlineVideo
        videoUrl={videoUrl}
        captions={[{ locale: videoLocale, url: '' }]}
      />
    </DesktopScreen>
  )
}

export default ProductLessonsPreview
