import React from 'react'
import { Box, Grid, Image, Flex } from '@chatterbug/aaron'

import Button, { BUTTON_VARIANTS } from 'src/components/Button/Button'

export type Props = {
  id: string
  subtitle: string
  title: string
  description: string
  image: {
    url: string
    width: number
    height: number
  }
  primaryAction?: {
    label: string
    url: string
  }
  secondaryAction?: {
    label: string
    url: string
  }
  className?: string
}

const CardWithImageAndCta: React.FC<Props> = ({
  id,
  subtitle,
  title,
  description,
  image,
  primaryAction,
  secondaryAction,
  className,
}) => {
  return (
    <Grid
      as="article"
      className={className}
      columns={{
        _: '1fr',
        tablet: '1fr 1fr',
      }}
      gridGap="3x"
      alignItems="center"
      aria-labelledby={id}
      px={{
        laptop: `${100 / 12}%`,
      }}
    >
      <Box
        textAlign="center"
        minHeight={{
          laptop: image.height,
        }}
        fontSize={0}
      >
        <Image
          src={image.url}
          width="100%"
          maxWidth={image.width}
          alt={title}
          loading="lazy"
        />
      </Box>
      <Box>
        <Box
          as="p"
          variant="text.paragraph"
          sx={{
            color: 'gray60',
            fontSize: 12,
            lineHeight: 1.2,
            textTransform: 'uppercase',
          }}
        >
          {subtitle}
        </Box>
        <Box
          variant="text.h3"
          mt="1x"
          sx={{
            fontWeight: 'semiBold',
            fontSize: 28,
            lineHeight: 1.2,
          }}
          id={id}
        >
          {title}
        </Box>
        <Box
          as="p"
          variant="text.paragraph"
          mt="2x"
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
        {primaryAction && (
          <Flex
            flexWrap="wrap"
            flexDirection="row"
            alignItems="center"
            mt={{ _: '3x', laptop: 56 }}
          >
            <Button
              label={primaryAction.label}
              href={primaryAction.url}
              height="44px"
              variant={BUTTON_VARIANTS.brand}
            />
            {secondaryAction && (
              <Box ml="1x">
                <Button
                  label={secondaryAction.label}
                  href={secondaryAction.url}
                  height="44px"
                  variant={BUTTON_VARIANTS.neutralBorderless}
                />
              </Box>
            )}
          </Flex>
        )}
      </Box>
    </Grid>
  )
}

export default CardWithImageAndCta
