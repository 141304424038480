import React from 'react'
import { useTranslate } from 'react-polyglot'
import { Box, Grid } from '@chatterbug/aaron'

import FullWidthBackground from 'src/components/FullWidthBackground/FullWidthBackground'
import PageContainer from 'src/components/PageContainer/PageContainer'
import StreamsLogo from 'src/components/StreamsLogo/StreamsLogo'
import LessonsLogo from 'src/components/LessonsLogo/LessonsLogo'
import OrgPackage from '../OrgPackage/OrgPackage'
import background from './assets/background.svg'
import { getLocalizedPath } from 'src/config/i18n'
import { useLocale } from 'src/lib/I18nContext'

const LOGO_HEIGHT = 51

const OrgPackages: React.FC = () => {
  const locale = useLocale()
  const t = useTranslate()

  return (
    <FullWidthBackground image={background} width={1439} height={659}>
      <PageContainer>
        <Grid
          maxWidth={756}
          mx="auto"
          pt={80}
          pb={128}
          columns={{
            _: '1fr',
            tablet: '1fr 1fr',
          }}
          gridTemplateAreas={{
            _: `
                "heading"
                "streams"
                "lessons"
            `,
            tablet: `
                "heading heading"
                "streams lessons"
            `,
          }}
          gridGap={{
            _: '3x',
            tablet: 48,
          }}
        >
          <Box
            variant="text.h1"
            textAlign="center"
            sx={{ gridArea: 'heading', justifySelf: 'center' }}
            maxWidth={520}
            pb={56 - 48}
            dangerouslySetInnerHTML={{
              __html: t(
                '<strong>Get started</strong> on your language <strong>learning journey</strong>'
              ),
            }}
          />
          <Box sx={{ gridArea: 'streams' }}>
            <OrgPackage
              logoSection={<StreamsLogo height={LOGO_HEIGHT} />}
              points={[
                t('Starting at <strong>2.99€</strong> per month'),
                t('Free <strong>7 day trial</strong> when you subscribe'),
                t('Cancel anytime'),
              ]}
              ctaLabel={t('Discover Streams')}
              ctaLink={getLocalizedPath(locale, '/streams')}
            />
          </Box>
          <Box sx={{ gridArea: 'lessons' }}>
            <OrgPackage
              logoSection={<LessonsLogo height={LOGO_HEIGHT} />}
              points={[
                t('Starting at <strong>20€</strong> per month'),
                t('Free <strong>14 day trial</strong>'),
                t('Cancel anytime'),
              ]}
              ctaLabel={t('Discover Lessons')}
              ctaLink={getLocalizedPath(locale, '/lessons')}
            />
          </Box>
        </Grid>
      </PageContainer>
    </FullWidthBackground>
  )
}

export default OrgPackages
