import React from 'react'
import {useTranslate} from 'react-polyglot'
import {Box, Flex} from '@chatterbug/aaron'

import OrgProduct from 'src/sections/OrgIndex/subcomponents/OrgProduct/OrgProduct'
import ProductStreamsPreview from 'src/sections/OrgIndex/subcomponents/ProductStreamsPreview/ProductStreamsPreview'
import ProductLessonsPreview from 'src/sections/OrgIndex/subcomponents/ProductLessonsPreview/ProductLessonsPreview'
import StreamsLogo from 'src/components/StreamsLogo/StreamsLogo'
import LessonsLogo from 'src/components/LessonsLogo/LessonsLogo'
import PageContainer from 'src/components/PageContainer/PageContainer'
import {getLocalizedPath} from 'src/config/i18n'
import {useLocale} from 'src/lib/I18nContext'
import LocalizedLink from "../../../../components/LocalizedLink/LocalizedLink";

const LOGO_HEIGHT = 51

const OrgProducts: React.FC = () => {
  const t = useTranslate()
  const locale = useLocale()

  return (
    <PageContainer>
      <Box as="ul" sx={{listStyle: 'none', padding: 0}}>
        <Box as="li" mt={{_: 128, tabletWide: 136}}>
          <OrgProduct
            title={t('One-on-one Tutoring with Native Speakers')}
            description={t(
              'Our method centers on private online lessons with native speaking tutors. We offer a fully integrated curriculum crafted by our teachers and language learning experts to prepare and guide you through every session.'
            )}
            platformType="desktop"
            iconSection={<LessonsLogo height={LOGO_HEIGHT}/>}
            previewPosition="left"
            previewSection={<ProductLessonsPreview/>}
            ctaLabel={t('Discover Lessons')}
            ctaLink={getLocalizedPath(locale, '/lessons')}
          />
        </Box>
        <Box as="li" mt={{_: 60, tabletWide: 32}}>
          <Flex alignItems="center" flexDirection="column" gridGap="1rem">
            <Box
              variant="text.paragraph"
              fontSize="1.125rem"
              textAlign="center"
              fontWeight="semiBold"
            >
              {t('Looking to offer Chatterbug Lessons to your employees?')}
            </Box>
            <Box>
              <LocalizedLink to="/business">
                <Box
                  variant="text.paragraph"
                  fontWeight="semiBold"
                  color="blue42"
                  borderBottomColor="blue42"
                  borderBottomStyle="solid"
                  borderBottomWidth="1.5px"
                  paddingBottom="0.38rem"
                  lineHeight="1.2"
                  sx={{
                    ':hover': {
                      color: 'blue30',
                      borderBottomColor: 'blue30',
                    },
                  }}
                >
                  {t('Learn about Chatterbug for Business')}
                </Box>
              </LocalizedLink>
            </Box>
          </Flex>
        </Box>
        <Box as="li" mt={{_: 60, tabletWide: 136}}>
          <OrgProduct
            title={t('Video-based Language Immersion')}
            description={t(
              'Watch bite-sized interactive videos presented by our engaging streamers. We have an enormous content library of language lessons in Spanish, French, German and English.'
            )}
            platformType="mobile"
            iconSection={<StreamsLogo height={LOGO_HEIGHT}/>}
            previewPosition="right"
            previewSection={<ProductStreamsPreview/>}
            ctaLabel={t('Discover Streams')}
            ctaLink={getLocalizedPath(locale, '/streams')}
          />
        </Box>
      </Box>
    </PageContainer>
  )
}

export default OrgProducts
