import React from 'react'
import { Box, Grid, Flex } from '@chatterbug/aaron'
import { useTranslate } from 'react-polyglot'

import { BlogPost } from 'src/types'
import ExtraLink from 'src/components/ExtraLink/ExtraLink'

export type Props = {
  post: BlogPost
}

const BlogPostCard: React.FC<Props> = ({ post }) => {
  const t = useTranslate()

  return (
    <Grid
      as="article"
      p={{ _: '2x', laptop: '3x' }}
      gridTemplateColumns="1fr"
      gridTemplateRows="auto auto 1fr auto"
      gridGap="2x"
      borderRadius={16}
      boxShadow="raised"
      bg="white100"
      minHeight="100%"
      sx={{ cursor: 'pointer' }}
      onClick={() => {
        window.location.href = post.link
      }}
    >
      <Box
        height={177}
        borderRadius="1x"
        backgroundImage={post.imageUrl ? `url(${post.imageUrl})` : undefined}
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        overflow="hidden"
      />
      <Box as="p" variant="text.h4">
        {post.title}
      </Box>
      <Box sx={{ alignSelf: 'flex-end' }}>
        <Box
          as="p"
          variant="text.paragraph"
          color="gray46"
          sx={{
            display: '-webkit-box',
            '-webkit-line-clamp': '3',
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
          }}
        >
          {post.description}
        </Box>
      </Box>
      <Flex flexDirection="row" justifyContent="flex-start">
        <ExtraLink
          label={t('Read')}
          href={post.link}
          onClick={(event) => event.stopPropagation()}
          data-name="read-blog-post"
        />
      </Flex>
    </Grid>
  )
}

export default BlogPostCard
