import React from 'react'
import { Box, Flex } from '@chatterbug/aaron'

import InlineVideo from 'src/components/InlineVideo/InlineVideo'
import MobileScreen from 'src/components/MobileScreen/MobileScreen'
import background from './assets/stream-background@2x.png'

const SIZE = {
  width: 1225,
  height: 692,
}

const ProductStreamsPreview: React.FC = () => {
  const videoUrl = `/assets/videos/streams-en-desktop.mp4`

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      mb={{ _: 80, tablet: 0 }}
      height="100%"
    >
      <Box
        sx={{
          position: 'relative',
          backgroundImage: `url(${background})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 100%',
          paddingTop: `${(SIZE.height * 100) / SIZE.width}%`,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '-15%',
            left: '50%',
            marginLeft: '-20%',
            width: '32%',
            transform: 'rotate(6deg)',
          }}
        >
          <MobileScreen>
            <InlineVideo videoUrl={videoUrl} captions={[]} />
          </MobileScreen>
        </Box>
      </Box>
    </Flex>
  )
}

export default ProductStreamsPreview
