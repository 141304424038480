import React from 'react'
import { useTranslate } from 'react-polyglot'

import CardWithImageAndCta from 'src/components/CardWithImageAndCta/CardWithImageAndCta'
import PageContainer from 'src/components/PageContainer/PageContainer'
import { CB_LINKS } from 'src/lib/app'
import image from './assets/team.svg'

const OrgAboutUs: React.FC = () => {
  const t = useTranslate()

  return (
    <PageContainer>
      <CardWithImageAndCta
        id="org_about_us"
        title={t('Who we are & how to join us')}
        subtitle={t('More about us')}
        description={t(
          "We're a group of entrepreneurs, linguists, and engineers from all over the world. Our goal is to make real language learning achievable by anyone. We come from <strong>GitHub, Amazon, Y Combinator, Harvard, Cambridge</strong> and more. We love learning languages!"
        )}
        image={{
          url: image,
          width: 386,
          height: 355,
        }}
        primaryAction={{
          label: t('Explore careers'),
          url: CB_LINKS.careers,
        }}
        secondaryAction={{
          label: t('Meet the team'),
          url: CB_LINKS.about,
        }}
        className="cb_org_team"
      />
    </PageContainer>
  )
}

export default OrgAboutUs
