import React from 'react'
import { Box, Flex, Grid } from '@chatterbug/aaron'

type Props = {
  children?: JSX.Element
  background?: {
    // If the background image conveys contextual information that the surrounding
    // text does not, please provide a descriptive text
    accessibilityText?: string
    url: string
  }
}

const CONTENT_HEIGHT = 860
const CONTENT_WIDTH = 441

const MobileScreen: React.FC<Props> = ({ children, background }) => {
  return (
    <Grid
      borderRadius={24}
      borderWidth={4}
      gridTemplateColumns="1fr"
      gridTemplateRows="1fr auto auto"
      borderColor="#4F5155"
      borderStyle="solid"
      backgroundColor="#1f1d2b"
      overflow="hidden"
      gridGap="0"
      paddingBottom="05x"
    >
      <Flex width="100%" justifyContent="center">
        <Box width="15%">
          <svg
            viewBox="0 0 34 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_i)">
              <circle cx="31.8001" cy="2.11674" r="1.85673" fill="#2A2C31" />
            </g>
            <rect
              x="0.236816"
              y="0.260193"
              width="28.0573"
              height="3.71346"
              rx="1.85673"
              fill="#2A2C31"
            />
            <defs>
              <filter
                id="filter0_i"
                x="29.9434"
                y="0.26001"
                width="3.71338"
                height="3.71344"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="0.379624" dy="0.379624" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow"
                />
              </filter>
            </defs>
          </svg>
        </Box>
      </Flex>
      <Box
        sx={{
          position: 'relative',
          paddingTop: `${(CONTENT_HEIGHT * 100) / CONTENT_WIDTH}%`,
          width: '100%',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            minHeight: '100%',
          }}
        >
          {children}
        </Box>
      </Box>
      <Flex width="100%" justifyContent="center">
        <Box width="30%">
          <svg
            viewBox="0 0 56 3"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.0952148"
              y="0.843445"
              width="55.2894"
              height="2.06304"
              rx="1.03152"
              fill="white"
            />
          </svg>
        </Box>
      </Flex>
    </Grid>
  )
}

export default MobileScreen
