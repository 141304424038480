import { Grid } from '@chatterbug/aaron'
import React, { useEffect, useRef } from 'react'
import { Box } from 'rebass'
import { canUseDOM } from 'src/lib/use-helpers'
import { SiteLocale } from 'src/types'

export type Props = {
  videoUrl: string
  captions: { locale: SiteLocale; url: string }[]
}

const InlineVideo: React.FC<Props> = ({ videoUrl, captions }) => {
  // const t = useTranslate()
  const vidElement = useRef<HTMLVideoElement>(null)
  const previewUrl = videoUrl.replace(/mp4$/i, 'jpg')

  // https://medium.com/@BoltAssaults/autoplay-muted-html5-video-safari-ios-10-in-react-673ae50ba1f5
  // https://github.com/facebook/react/issues/6544
  useEffect(() => {
    if (!vidElement.current || !canUseDOM()) return

    const prefersReducedMotion = window.matchMedia(
      '(prefers-reduced-motion: reduce)'
    ).matches
    if (!prefersReducedMotion) {
      vidElement.current.autoplay = true
    }
    // const tracks = Array.from(vidElement.current.textTracks)
    // for (const track of tracks) {
    //   track.mode = 'hidden'
    // }

    vidElement.current.setAttribute('muted', '')
    vidElement.current.setAttribute('playsinline', '')

    vidElement.current.controls = false
    vidElement.current.muted = true

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    const timer = setTimeout(async () => {
      try {
        if (!prefersReducedMotion) {
          await vidElement.current?.play()
        }
      } catch (error) {
        console.log(error)
      }
    }, 0)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  // NB: Work around https://github.com/testing-library/react-testing-library/issues/470
  // where setting 'muted' breaks tests
  const vid = (
    <video
      ref={vidElement}
      playsInline
      loop
      muted={canUseDOM()}
      width="100%"
      height="100%"
      crossOrigin="anonymous"
      className="cb-inline-video"
      // we have a custom backdrop instead
      // poster={previewUrl}
      style={{
        objectFit: 'contain',
      }}
    >
      <source src={videoUrl} type='video/mp4; codecs="hvc1"' />
      <source src={videoUrl.replace(/mp4$/i, 'webm')} type="video/webm" />
      {/* TODO: Find a way to make them hidden by default */}
      {/*{captions.map((caption, index) => (*/}
      {/*  <track*/}
      {/*    key={`video-caption-${caption.locale}`}*/}
      {/*    label={getLocalizedLanguageLabel(caption.locale, t)}*/}
      {/*    kind="captions"*/}
      {/*    srcLang={caption.locale}*/}
      {/*    src={caption.url}*/}
      {/*    default={index === 0}*/}
      {/*  />*/}
      {/*))}*/}
    </video>
  )

  return (
    <Grid
      className="video-container"
      gridTemplateColumns="1fr"
      gridTemplateRows="1fr"
      // overflow="hidden"
      height="100%"
      width="100%"
      gridGap="0"
    >
      <Box
        width="100%"
        height="100%"
        sx={{
          background: `url(${previewUrl}) no-repeat`,
          backgroundSize: 'contain',
          backgroundPosition: 'center center',
          gridColumn: '1 / span 1',
          gridRow: '1 / span 1',
        }}
      />
      <Box
        overflow="hidden"
        height="100%"
        sx={{
          gridColumn: '1/ span 1',
          gridRow: '1 / span 1',
        }}
      >
        {vid}
      </Box>
    </Grid>
  )
}

export default InlineVideo
